import React from "react";
import HeadingSection from "./HeadingSection";
import {
  Box,
  Flex,
  Spacer,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Container,
  Icon,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { FaLocationDot } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
import { TimelineData } from "../Data/Apri";
import { motion } from "framer-motion";

export default function Experience(props) {
  return (
    <Box color="white" {...props}>
      <HeadingSection
        NameHeading="Education & Experience"
        NameSection="EDUCATION & EXPERIENCE"
        textAlign="center"
      />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <Container maxW="4xl" py={20}>
          {TimelineData.map((item, idx) => {
            return (
              <Accordion allowToggle key={idx}>
                <AccordionItem>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" py={2}>
                      <Flex alignItems="center">
                        <Box>
                          <Text
                            fontSize={{ base: "md", md: "lg" }}
                            fontWeight="bold"
                          >
                            {item.position}
                          </Text>
                          <Flex
                            gap={{ base: "1", md: "5" }}
                            mt={2}
                            direction={{ base: "column", md: "row" }}
                            fontSize={{ base: "sm", md: "md" }}
                          >
                            <Text>
                              <Icon as={FaBuilding} mr={2} />
                              {item.title}
                            </Text>
                            <Text>
                              <Icon as={FaLocationDot} h={4} mr={2} />
                              {item.location}
                            </Text>
                          </Flex>
                        </Box>
                        <Spacer />
                        <Flex
                          direction="column"
                          alignItems="flex-end"
                          gap={2}
                          fontSize={{ base: "x-small", md: "small" }}
                        >
                          <Text
                            bgGradient="linear(to-tl, green.100, green.300)"
                            w="fit-content"
                            rounded={50}
                            px={3}
                            color="BgColor"
                          >
                            {item.category}
                          </Text>
                          <Text>{item.date}</Text>
                        </Flex>
                      </Flex>
                    </Box>
                    {/* <AccordionIcon /> */}
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Text>
                      {item.experience.length > 1 ? (
                        <UnorderedList>
                          {item.experience.map((desc, idx) => {
                            return <ListItem key={idx}>{desc}</ListItem>;
                          })}
                        </UnorderedList>
                      ) : (
                        <Text>{item.experience}</Text>
                      )}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            );
          })}
        </Container>
      </motion.div>
    </Box>
  );
}
