import { Box, Button, Flex, Spacer, Text, Image } from "@chakra-ui/react";
import instagramIcon from "../Assets/Instagram.png";
import linkedinIcon from "../Assets/Linkeid.png";
import githubIcon from "../Assets/Github.png";
import React from "react";
import { Sapiens } from "./Sapiens";
import { motion } from "framer-motion";

export default function Aboutme(props) {
  const socialMediaIcon = [
    {
      name: "instagram",
      icon: instagramIcon,
      link: "https://www.instagram.com/aprnna.c/?hl=id",
    },
    {
      name: "linkedin",
      icon: linkedinIcon,
      link: "https://www.linkedin.com/in/apriana-cahya-161555265/",
    },
    {
      name: "github",
      icon: githubIcon,
      link: "https://github.com/aprnna",
    },
  ];

  const textVariants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };
  const childTextVariants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
  };
  const textVariants2 = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 1,
      },
    },
  };
  const textVariants3 = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 1.5,
      },
    },
  };
  const buttonVariants = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        delay: 2,
      },
    },
  };

  return (
    <Box marginInline={{ base: 5, md: 10 }} color="white" {...props}>
      <Flex height="100%">
        <Flex
          direction="column"
          // display={{ base: "block", md: "flex" }}
          height={{ base: "70%", md: "60%" }}
        >
          <Box>
            <motion.div
              variants={textVariants}
              initial="hidden"
              animate="visible"
            >
              <Text fontSize={{ base: "xl", md: "4xl" }} fontWeight="extrabold">
                Hello 👋
                <motion.div variants={childTextVariants}>
                  My Name is Apriana Cahya
                </motion.div>
              </Text>
            </motion.div>
            <motion.div
              variants={textVariants2}
              initial="hidden"
              animate="visible"
            >
              <Text
                bgGradient="linear(to-tl, green.100, green.300)"
                bgClip="text"
                fontSize={{ base: "3xl", md: "5xl" }}
                fontWeight="extrabold"
              >
                I am Frontend Developer
              </Text>
            </motion.div>
            <motion.div
              variants={textVariants3}
              initial="hidden"
              animate="visible"
            >
              <Text fontSize={{ base: "lg", md: "3xl" }}>
                Undergraduate student of Informatics <br />
                Engineering at Universitas Komputer Indonesia
              </Text>
            </motion.div>
          </Box>
          <Spacer />
          <motion.div
            variants={buttonVariants}
            initial="hidden"
            animate="visible"
          >
            <Flex
              gap={5}
              mt={10}
              variants={buttonVariants}
              initial="hidden"
              animate="visible"
            >
              <Button
                colorScheme="white"
                borderColor="green.300"
                variant="outline"
                rounded={30}
                _hover={{ bg: "green.300", color: "BgColor" }}
              >
                Resume
              </Button>
              {socialMediaIcon.map((item) => {
                return (
                  <Button
                    variant="outline"
                    colorScheme="gray"
                    borderColor="green.300"
                    borderRadius="full"
                    padding={3}
                    key={item.name}
                  >
                    <a href={item.link}>
                      <Image height="4" src={item.icon} alt={item.name} />
                    </a>
                  </Button>
                );
              })}
            </Flex>
          </motion.div>
        </Flex>
        <Spacer />
        <Box display={{ base: "none", lg: "block" }}>
          <Sapiens height={468} />
        </Box>
      </Flex>
    </Box>
  );
}
