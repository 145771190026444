import {Box, ChakraProvider,Container,Img,extendTheme } from '@chakra-ui/react';
import Navbar from './Components/Navbar';
import Aboutme from './Components/Aboutme';
import Projects from './Components/Projects';
import accent1 from './Assets/accent1.png';
import accent2 from './Assets/accent2.png';
import Skills from './Components/Skills';
import Experience from './Components/Experience';
import BgGrid from './Assets/Background-Grid.png';
import Footer from './Components/Footer';
import BackgroundFooter from "./Assets/background-footer.png";
import '../src/app.css';
function App() {
  
  const theme = extendTheme({
    colors:{
      BgColor:'#111827',
      green:{
        100:'#3BDBE8',
        200:'#3BE8D3',
        300:'#3BE8B0',
        400:'#3BE88D',
        500:'#3BE86B',
      },
      purple:{
        100:'#E83BDB',
        200:'#E83BB8',
        300:'#B03BE8',
        400:'#8D3BE8',
        500:'#6B3BE8',
      }
    },

    fonts: {
      body: 'Plus Jakarta Sans, sans-serif',
      Heading: 'Plus Jakarta Sans, sans-serif',
    },
  })
  return (
    <ChakraProvider theme={theme}>
      <Box bgColor='BgColor' position='relative'>
        <Img src={accent1} position='absolute' top={{ base: "800", md: "720" }} right={0} h={{ base: "150", md: "348" }} zIndex={0}/>
        <Img src={accent2} position='absolute' top={{ base: "1200", md: "800" }}  h={{ base: "500", md: "754" }} zIndex={0}/>
        <Img src={BgGrid} position='absolute' top={2100} zIndex={0}  w='full' h={1000} objectPosition='center' objectFit='cover'/>
        <Img src={BackgroundFooter} position='absolute' w='full' h={700} objectPosition='top' objectFit='cover' bottom={0} zIndex={0}/>
        <Container maxW='8xl' p={0} zIndex={10} position='relative'>
          <Navbar h='10vh'/>
          <Aboutme h='800' py={100} id="aboutme"/>
          <Projects minH="784" id="projects"/>
          <Skills minH='538' id="skills"/>
          <Experience minH='523' py={100} id="experience"/>
          <Footer minH='800' id="contact"/>
        </Container>
      </Box>
      
    </ChakraProvider>
  )
}

export default App;
