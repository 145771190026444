import MyBengkelIMG from "../Assets/Projects/MyBengkel.png";
import BagiKopiIMG from "../Assets/Projects/BagiKopi.png";
import SeacinemaIMG from "../Assets/Projects/Seacinema.png";
import InstagramIcon from "../Assets/SosmedFooter/Instagram.png";
import LinkedInIcon from "../Assets/SosmedFooter/Linkedin.png";
import MediumIcon from "../Assets/SosmedFooter/Medium.png";
import GithubIcon from "../Assets/SosmedFooter/Github.png";
import EmailIcon from "../Assets/SosmedFooter/Email.png";

export const ProfileData = {
  name: "Apriana",
  role: "Frontend Developer",
  skills: [
    "HTML",
    "CSS",
    "JavaScript",
    "React",
    "GIT",
    "Github",
    "Responsive Web",
  ],
  socialMedia: [
    {
      name: "Email",
      link: "mailto:aprianaacahya04@gmail.com",
      icon: EmailIcon,
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/apriana-cahya-161555265/",
      icon: LinkedInIcon,
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/aprnna.c/?hl=id",
      icon: InstagramIcon,
    },
    {
      name: "Medium",
      link: "https://medium.com/@aprianacahya04",
      icon: MediumIcon,
    },
    {
      name: "Github",
      link: "https://github.com/aprnna",
      icon: GithubIcon,
    },
  ],
};

export const ProjectsData = [
  {
    id: 0,
    title: "My Bengkel",
    description:
      "My Bengkel is a software that can help people who have a workshop business with bookkeeping. The bookkeeping in question is information on the location of spare parts, the number of spare parts available, expenses and income.",
    date: "17 Jul 2023 - 24 Jul 2023",
    techStack: ["Java", "Java Swing", "My SQL"],
    sourceCode: "https://github.com/Klowrr/TUBES-PBO",
    img: MyBengkelIMG,
    demo: null,
  },
  {
    id: 1,
    title: "BagiKopi",
    description:
      "BagiKopi, an innovative app makes it easy for users to explore and find the perfect coffee with detailed descriptions. Users can order and pay in cash/cashless, avoiding queues. All transactions and orders are recorded in a structured manner, aiding financial analysis and order checking for shop owners.",
    date: "8 Jan 2024 - 15 Feb 2024",
    techStack: ["React", "Tailwind", "Tremor", "Supabase"],
    sourceCode: "https://github.com/aprnna/Tubes-IMK-BagiKopi",
    img: BagiKopiIMG,
    demo: "https://tubes-imk-bagi-kopi.vercel.app/",
  },
  {
    id: 2,
    title: "Seacinema",
    description:
      "Seacinema is an innovative web application utilizing MERN Stack with a responsive user interface for efficient movie ticket reservations. It offers a pleasant booking experience with excellent features, including comprehensive film selection, account personalization with balance and transaction history, and refunds for schedule changes.",
    date: "23 Dec 2023 - 04 Jan 2024",
    techStack: ["MongoDB", "Express.Js", "React", "Node.js"],
    sourceCode: "https://github.com/aprnna/frontend-seacinema",
    img: SeacinemaIMG,
    demo: "https://seacinema-876cf.web.app/",
  },
];

export const TimelineData = [
  {
    id: 0,
    date: "Sep 2022 - Present",
    title: "University Computer Indonesia",
    position: "Informatics Engineering",
    category: "Education",
    location: "Bandung",
    experience: ["Bachelor of Computer Science"],
  },
  {
    id: 1,
    date: "Feb 2023 - Present",
    title: "Unikom CodeLabs",
    location: "Bandung",
    category: "Organization",
    position: "Member",
    experience: [
      "Learned and practiced frontend engineering with attention to code quality and web performance (React, JavaScript)",
      "Collaborate between divisions to create a product that can solve problems in society.",
      "Successfully accepted 15 new members from 100 applicants",
    ],
  },
  {
    id: 2,
    date: "Mar 2023 - Present",
    title: "GDSC Unikom",
    location: "Bandung",
    category: "Organization",
    position: "Head Of Tech Team",
    experience: [
      "Served as the Head of Tech in the GDSC Core Team",
      "Designed and developed learning paths for GDSC members",
      "Ensured a comprehensive and up-to-date curriculum with the latest technologies",
      "Guided and supported GDSC members in learning new technologies",
      "Collaborated with other divisions to integrate technical aspects into GDSC initiatives",
    ],
  },
  {
    id: 3,
    date: "10 Aug 2023 - 28 Aug 2023 ",
    title: "Compest 15",
    location: "Remote",
    category: "Course",
    position: "Front End Developer",
    experience: [
      "Selected as one of 20 participants out of a pool of over 3,495 applicants.",
      "Learning essential software engineering concepts, such as SDLC, Clean Code, Clean Architecture, Design Patterns, CI/CD, Security, etc.",
      "Worked with a team of four to Develop a website from user stories given for final project",
      "Take on the role of Front-end Development for the final project.",
      "Diligently contributed throughout a three-week boot camp that culminated in a final project presentation.",
      "Teamed up with three other academy divisions - Data Science, Product Management, and User Experience - for a Case Study Presentation on Graduation Night.",
    ],
  },
];
