import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
export default function HeadingSection({ NameSection, NameHeading, ...props }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <Box {...props}>
        <Heading fontSize={{ base: "x-small", md: "sm" }} color="white">
          {NameSection}
        </Heading>
        <Heading
          fontSize={{ base: "3xl", md: "4xl" }}
          fontWeight="extrabold"
          bgGradient="linear(to-tl, green.100, green.300)"
          bgClip="text"
        >
          {NameHeading}
        </Heading>
      </Box>
    </motion.div>
  );
}
