import { Flex, Heading, Img, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { ProfileData } from "../Data/Apri";

export default function Footer(props) {
  return (
    <Flex color="BgColor" {...props} direction="column" pb={50} gap={10} mx={5}>
      <Spacer />
      <Heading fontSize={{ base: "2xl", md: "3xl" }} textAlign="center">
        Let’s connect with me on social media!
      </Heading>
      <Flex alignItems="center" justifyContent="center" gap={5}>
        {ProfileData.socialMedia.map((item, idx) => {
          return (
            <a href={item.link} target="_blank" rel="noreferrer" key={idx}>
              <Img src={item.icon} alt={item.name} h={50} />
            </a>
          );
        })}
      </Flex>
      <Text textAlign="center" fontSize="sm">
        Designed and Developed with 💖 by Apri
      </Text>
    </Flex>
  );
}
