import React from "react";
import {
  Box,
  Flex,
  Heading,
  Img,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { ProjectsData } from "../Data/Apri.js";
import HeadingSection from "./HeadingSection.jsx";
import { motion } from "framer-motion";

export default function Projects(props) {
  const ColorCar = [
    "rgba(176,59,232,0.2)",
    "rgba(59,232,176,0.2)",
    "rgba(232,176,59,0.2)",
  ];
  return (
    <Box color="white" p={10} {...props}>
      <HeadingSection
        NameSection="PROJECTS"
        NameHeading="My Latest Projects"
        textAlign="center"
      />
      <Flex gap={10} my={10} justifyContent="center" flexWrap="wrap">
        {ProjectsData.map((item, idx) => {
          return (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <Card ColorCard={ColorCar} idx={idx} item={item} />
            </motion.div>
          );
        })}
      </Flex>
    </Box>
  );
}

function Card({ item, idx, ColorCard, ...props }) {
  return (
    <Flex
      bg={ColorCard[idx % ColorCard.length]}
      height={332}
      width={332}
      borderRadius={10}
      direction="column"
      alignItems="center"
      position="relative"
      role="group"
      key={idx}
    >
      <Box
        p={5}
        opacity="1"
        _groupHover={{ opacity: "0" }}
        transition="opacity 0.2s ease-in-out"
      >
        <Heading fontSize="xl" textAlign="center">
          {item.title}
        </Heading>
        <Flex gap={2} mt={4} justifyContent="center" wrap="wrap">
          {item.techStack.map((tech, idx) => {
            return (
              <Text
                width={"fit-content"}
                key={idx}
                borderWidth={2}
                bg="white"
                color="black"
                fontSize="sm"
                px={3}
                borderRadius={10}
              >
                {tech}
              </Text>
            );
          })}
        </Flex>
      </Box>
      <Spacer />
      <Img src={item.img} alt={item.title} _groupHover={{ display: "none" }} />

      {/* When Hover */}
      <Flex
        p={4}
        position={"absolute"}
        direction="column"
        opacity={0}
        transform="translateY(10px)"
        _groupHover={{ opacity: 1, transform: "translateY(0)" }}
        transition="opacity 0.5s ease, transform 0.5s ease"
        height="100%"
        width="100%"
      >
        <Box>
          <Heading fontSize="xl">{item.title}</Heading>
          <Text fontSize="sm" mt={4}>
            {item.description}
          </Text>
        </Box>
        <Spacer />
        {item.demo && (
          <LinkBox
            p={3}
            bgColor={ColorCard[idx % ColorCard.length]}
            textAlign="center"
            rounded={10}
            _hover={{ filter: "brightness(0.8)" }}
          >
            <LinkOverlay href={item.demo}>Demo</LinkOverlay>
          </LinkBox>
        )}
      </Flex>
    </Flex>
  );
}
