import React from "react";
import { Box, Button, Flex, Link, Spacer } from "@chakra-ui/react";
export default function Navbar(props) {
  return (
    <Box bg="BgColor" w="100%" p={4} color="white" {...props}>
      <Flex
        minWidth="max-content"
        alignItems="center"
        gap={10}
        marginInline={10}
        display={{ base: "none", md: "flex" }}
      >
        <Spacer />
        <Link href="#aboutme">About Me</Link>
        <Link href="#skills">Tools & Skills</Link>
        <Link href="#projects">Project</Link>
        <Link href="#experience">Education & Experience</Link>
        <Link href="#contact">
          <Button
            _hover={{ bg: "green.300", color: "BgColor" }}
            color="white"
            borderColor="green.300"
            variant="outline"
          >
            Contact
          </Button>
        </Link>
      </Flex>
    </Box>
  );
}
