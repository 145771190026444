import React from "react";
import { Box, Img, Text } from "@chakra-ui/react";
import HeadingSection from "./HeadingSection";
import HtmlIcon from "../Assets/TechSkill/Html.png";
import CssIcon from "../Assets/TechSkill/Css.png";
import JsIcon from "../Assets/TechSkill/Js.png";
import NextIcon from "../Assets/TechSkill/Next.png";
import ReactIcon from "../Assets/TechSkill/React.png";
import MysqlIcon from "../Assets/TechSkill/Mysql.png";
import MongoDBIcon from "../Assets/TechSkill/Mongodb.png";
import TailwindIcon from "../Assets/TechSkill/Tailwind.png";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
export default function Skills(props) {
  const techSkill = [
    HtmlIcon,
    CssIcon,
    JsIcon,
    NextIcon,
    ReactIcon,
    MysqlIcon,
    MongoDBIcon,
    TailwindIcon,
  ];

  return (
    <Box p={{ base: 5, md: 10 }} py={20} color="white" {...props}>
      <HeadingSection NameSection="SKILLS" NameHeading="My Skills" />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <Text maxW="800px" my={5} fontSize={{ base: "lg", md: "2xl" }}>
          I have extensive experience working with a variety of technologies as
          a developer. I've developed and maintained multiple projects using
          these technologies, and I'm always eager to learn more.
        </Text>
      </motion.div>
      <Box py={10}>
        <Marquee direction="right" speed={80} pauseOnHover>
          {[...techSkill, ...techSkill].map((item, idx) => {
            return (
              <Img
                src={item}
                alt="Tech Skill"
                h={{ base: "50", md: "100" }}
                m={5}
                key={idx}
              />
            );
          })}
        </Marquee>
      </Box>
    </Box>
  );
}
